<template>
  <div class="view-home winner-page">
    <b-modal id="sms-modal" hide-footer hide-header centered>
      <button class="btn-close-top" @click="$bvModal.hide('sms-modal')">
        <img width="36" src="/images/Button-close.svg">
      </button>
      <h3 style="margin-top: 2rem" class="sms-modal-title">{{ $t('signup.enter-code') }}</h3>
      <div class="sms-modal-text">{{ $t('signup.sent-to') }}{{ this.account.email }}</div>
      <div id="form" class="sms-modal-form">
        <CodeInput :field-width="44" :field-height="56" :fields="4" class="input" v-on:change="onChange"
                   v-on:complete="onComplete" style="margin-bottom: 2rem"/>
        <p style="margin-bottom: 4rem" class="text-danger text-center pt-3 " v-if="invalidCode">
          {{ $t('signup.incorrect-code') }}</p>

      </div>
    </b-modal>

    <div class="teacher-page ">
      <div class="container ">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb pl-0">
            <li class="breadcrumb-item"><a href="#">{{ $t('breadcrumb.main-page') }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.for-teachers') }}</li>
          </ol>
        </nav>
        <div class="page-title  text-center text-md-left p-2">
          {{ $t('for-teachers.title') }}
        </div>
      </div>
    </div>

    <div class="teacher-page pb-5">
      <div class="container pb-5">
        <div class="row mb-2">
          <div class="col-lg-7 ">
            <h3 class="text-danger font-weight-bold
                        mt-3 mb-lg-5 text-center text-md-left teacher-title">{{ $t('for-teachers.desc-title') }}</h3>
            <div class="col-lg-12 m-auto action-step">
              <ol class="step-list">
                <li class="step-item">
                  <img src="/images/step-1.svg" alt="">
                  <div class="title">
                    {{ $t('for-teachers.step-1') }}
                  </div>
                </li>
                <li class="step-item">
                  <img src="/images/step-2.svg" alt="">
                  <div class="title">
                    {{ $t('for-teachers.step-2') }}
                  </div>
                </li>
                <li class="step-item">
                  <img src="/images/step-3.svg" alt="">
                  <div class="title">
                    {{ $t('for-teachers.step-3') }}
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-lg-5 ">
            <div class="card auth-form">
              <b-tabs class="form-header">
                <button class="auth-form__close">
                  <img src="/images/delete-icon.svg">
                </button>
                <ul class="nav nav-tabs border-0" id="myTab" role="tablist">
                  <li v-on:click="isHiddenSignup = false; isHiddenSignin = true" class="nav-item select-sign">
                    <a :class="{ active: isHiddenSignin }" class="nav-link form-title">
                      {{ $t('signup.title-request') }}
                    </a>
                  </li>
                  <li v-on:click="isHiddenSignup = true; isHiddenSignin = false" class="nav-item select-sign">
                    <a :class="{ active: isHiddenSignup }" class="nav-link form-title">
                      {{ $t('signin.main-title') }}
                    </a>
                  </li>
                </ul>
              </b-tabs>
              <div class="tab-content">
                <div class="auth-form__application-steps">
                  <div v-if="!isHiddenSignup" style="justify-content: center;" class="d-flex pt-3 application-steps">
                    <div @click="changeStep(1)" v-bind:class="{ stepActive : CurentStep == 1}" class="step-1">
                      {{ $t('signup.step') }} 1
                    </div>
                    <div @click="changeStep(2)" v-bind:class="{ stepActive : CurentStep == 2}" class="step-2">
                      {{ $t('signup.step') }} 2
                    </div>
                    <div :disabled="step2" @click="changeStep(3)" v-bind:class="{ stepActive : CurentStep == 3}"
                         class="step-3">
                      {{ $t('signup.step') }} 3
                    </div>
                  </div>
                </div>
                <div v-if="!isHiddenSignup" id="home">
                  <div class="form-body">
                    <div v-show="CurentStep==1">
                      <form-group>
                        <el-select
                            :placeholder="$t('for-teachers.role')"
                            v-model="account.role"
                            filterable>
                          <el-option
                              :key=2
                              :label="$t('for-teachers.role-1')"
                              :value="2">
                          </el-option>
                          <el-option
                              :key=3
                              :label="$t('for-teachers.role-2')"
                              :value=3>
                          </el-option>
                          <el-option
                              :key=4
                              :label="$t('for-teachers.role-3')"
                              :value=4>
                          </el-option>
                        </el-select>
                      </form-group>
                      <form-group>
                        <el-input
                            :placeholder="$t('profile.profile.last-name-label')"
                            v-model="account.last_name"
                            clearable>
                        </el-input>
                      </form-group>
                      <form-group>
                        <el-input
                            :placeholder="$t('profile.profile.first-name-label')"
                            v-model="account.first_name"
                            clearable>
                        </el-input>
                      </form-group>
                      <form-group>
                        <el-input
                            :placeholder="$t('profile.profile.patronymic-label')"
                            v-model="account.patronymic"
                            clearable>
                        </el-input>
                      </form-group>
                      <el-date-picker
                          :picker-options="pickerOptions"
                          style="width: 100%;"
                          v-model="account.birth_date"
                          type="date"
                          :placeholder="this.$t('signup.birth-date-label')"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                      <form-group>
                        <el-select
                            :placeholder="$t('profile.profile.region-label')"
                            v-model="account.region_id"
                            :loading="regionsLoading"
                            @change="getCities"
                            filterable>
                          <el-option
                              v-for="item in regions"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                          </el-option>
                        </el-select>
                      </form-group>
                      <form-group>
                        <el-select
                            :placeholder="$t('profile.profile.district-label')"
                            v-model="account.city_id"
                            :loading="citiesLoading"
                            @change="getLocalities"
                            filterable>
                          <el-option
                              v-for="item in cities"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                          </el-option>
                        </el-select>
                      </form-group>
                      <form-group>
                        <el-select
                            autocomplete="nope"
                            v-model="account.locality_id"
                            :placeholder="$t('profile.profile.city-label')"
                            filterable>
                          <el-option
                              v-for="item in localities"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                          </el-option>
                        </el-select>
                      </form-group>
                      <div class="text-center">
                        <button @click="changeStep(2)" type="button" class="btn btn-primary">{{
                            $t('for-teachers.next')
                          }}
                        </button>
                      </div>
                    </div>

                    <div v-show="CurentStep==2">
                      <form-group>
                        <el-input
                            id="email"
                            :placeholder="$t('profile.profile.email-label')"
                            :disabled="emailConfirm || step2"
                            v-model="account.email"
                            @change="emailValidate()"
                        >
                        </el-input>
                      </form-group>
                      <form-group>
                        <el-input
                            :placeholder="$t('profile.profile.phone-label')"
                            v-model="phone"
                            v-mask="'+7 (###) ### ##-##'"
                        >
                        </el-input>
                      </form-group>
                      <!--                      <form-group v-show="this.account.role==2 || this.account.role==3">-->
                      <!--                        <el-select-->
                      <!--                            v-model="account.subject"-->
                      <!--                            :placeholder="$t('for-teachers.subject')"-->
                      <!--                        >-->
                      <!--                          <el-option-->
                      <!--                              v-for="item in subjects"-->
                      <!--                              :key="item.id"-->
                      <!--                              :label="item.name"-->
                      <!--                              :value="item.id">-->
                      <!--                          </el-option>-->
                      <!--                        </el-select>-->
                      <!--                      </form-group>-->
                      <form-group v-show="this.account.role==2">
                        <el-select
                            v-model="account.language_id"
                            :placeholder="$t('for-teachers.language')"
                        >
                          <el-option
                              :key="1"
                              :label="$t('profile.profile.language-kz')"
                              :value="1">
                          </el-option>
                          <el-option
                              :key="2"
                              :label="$t('profile.profile.language-ru')"
                              :value="2">
                          </el-option>
                        </el-select>
                      </form-group>
                      <form-group>
                        <el-select
                            autocomplete="nope"
                            v-model="account.change_of_training_id"
                            :placeholder="$t('profile.profile.change_of_training-placeholder')">
                          <el-option
                              :key="1"
                              :label="1+' '+$t('profile.profile.change_of_training')"
                              :value="1">
                          </el-option>
                          <el-option
                              :key="2"
                              :label="2+' '+$t('profile.profile.change_of_training')"
                              :value="2">
                          </el-option>
                        </el-select>
                      </form-group>
                      <div class="uppload-file-wrapper">
                        <div class="uppload-file-header"><span style="cursor: pointer"><span
                            class="uppload-icon"></span><span
                            class="uppload-file-header-text">{{ $t('signup.resume-add') }}</span></span>
                          <input type="file" @change="onFileChange">
                        </div>
                        <div class="uppload-file-element" v-for="(image, i) in account.images">
                          <span class="uppload-text">{{ imagesName[i] }}</span>
                          <button class="uppload-delete" @click="removeImage(i)"></button>
                        </div>
                      </div>
                      <div class="text-center">
                        <button :disabled="step2" @click="changeStep(3)" type="button" class="btn btn-primary">{{
                            $t('for-teachers.next')
                          }}
                        </button>
                      </div>
                    </div>

                    <div v-show="CurentStep==3">
                      <div class="teacher-text-purple">{{ $t('for-teachers.select-date') }}</div>
                      <form-group>
                        <el-date-picker
                            style="width: 100%;"
                            v-model="account.interview_date"
                            type="date"
                            placeholder="Дата"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            @change="getBusyTime()"
                        >
                        </el-date-picker>
                      </form-group>

                      <!--                            <datepicker -->
                      <!--                                :language="ru"-->
                      <!--                                placeholder="Дата"-->
                      <!--                                wrapper-class="teacher-date-picker"-->
                      <!--                                input-class="teacher-date-picker__input"-->
                      <!--                                calendar-class="teacher-date-picker__calendar"-->
                      <!--                                format="yyyy-MM-dd"-->
                      <!--                                value-format="yyyy-MM-dd"-->
                      <!--                                monday-first="true"-->
                      <!--                            >-->
                      <!--                            </datepicker>-->

                      <div class="teacher-text-purple">{{ $t('for-teachers.select-time') }}</div>
                      <el-radio-group class="teacher-hour-wrapper" v-model="account.interview_hour">
                        <div class="teacher-hour-column">
                          <el-radio id="time-9-00" :disabled="hours.hour0900" :value="900" label="09:00"></el-radio>
                          <el-radio id="time-9-30" :disabled="hours.hour0930" :value="930" label="09:30"></el-radio>
                          <el-radio id="time-10-00" :disabled="hours.hour1000" :value="1000" label="10:00"></el-radio>
                          <el-radio id="time-10-30" :disabled="hours.hour1030" :value="1030" label="10:30"></el-radio>
                          <el-radio id="time-11-00" :disabled="hours.hour1100" :value="1100" label="11:00"></el-radio>
                          <el-radio id="time-11-30" :disabled="hours.hour1130" :value="1130" label="11:30"></el-radio>
                          <el-radio id="time-12-00" :disabled="hours.hour1200" :value="1200" label="12:00"></el-radio>
                          <el-radio id="time-12-30" :disabled="hours.hour1230" :value="1230" label="12:30"></el-radio>
                          <el-radio id="time-13-00" :disabled="hours.hour1300" :value="1300" label="13:00"></el-radio>
                          <el-radio id="time-13-30" :disabled="hours.hour1330" :value="1330" label="13:30"></el-radio>
                        </div>
                        <div class="teacher-hour-column">
                          <el-radio id="time-14-00" :disabled="hours.hour1400" :value="1400" label="14:00"></el-radio>
                          <el-radio id="time-14-30" :disabled="hours.hour1430" :value="1430" label="14:30"></el-radio>
                          <el-radio id="time-15-00" :disabled="hours.hour1500" :value="1500" label="15:00"></el-radio>
                          <el-radio id="time-15-30" :disabled="hours.hour1530" :value="1530" label="15:30"></el-radio>
                          <el-radio id="time-16-00" :disabled="hours.hour1600" :value="1600" label="16:00"></el-radio>
                          <el-radio id="time-16-30" :disabled="hours.hour1630" :value="1630" label="16:30"></el-radio>
                          <el-radio id="time-17-00" :disabled="hours.hour1700" :value="1700" label="17:00"></el-radio>
                          <el-radio id="time-17-30" :disabled="hours.hour1730" :value="1730" label="17:30"></el-radio>
                          <el-radio id="time-18-00" :disabled="hours.hour1800" :value="1800" label="18:00"></el-radio>
                        </div>
                      </el-radio-group>
                      <!-- <button @click="upploadFile()"></button> -->
                      <div class="form-check pl-0 pt-4">
                        <label class="form-check-label" for="all">
                          <div v-html="$t('for-teachers.accept')"></div>
                          <!-- {{$t("for-teachers.accept")}}  -->
                          <input class="form-check-input" type="checkbox" name="filter" id="all" value="0"
                                 v-model="acceptData">
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <button @click="sendRequest()" type="button" class="btn btn-primary btn-block"
                              :disabled="!acceptData ||  buttonBlock">{{ $t('for-teachers.submit-application') }}
                      </button>
                    </div>
                  </div>

                  <div class="form-footer pt-0 d-none">
                    <button type="button" class="btn btn-primary btn-block">{{ $t('signup.log-in-button') }}</button>
                    <div class="form-footer-title">
                      {{ $t('signup.already-have-account') }} <a href="#">{{ $t('signup.log-in-button') }}</a>
                    </div>
                  </div>
                </div>

                <div v-if="!isHiddenSignin" id="profile">
                  <div class="form-subtitle">
                    {{ $t('signin.title-2') }}
                  </div>
                  <div class="form-body">
                    <login-form></login-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {minLength, minValue, required, email, helpers} from "vuelidate/lib/validators";
import CodeInput from "vue-verification-code-input";
import LoginForm from "../../components/LoginForm";
import Datepicker from 'vuejs-datepicker';
import {en, ru, kk} from 'vuejs-datepicker/dist/locale'

const passwordHelper = helpers.regex('passwordHelper', /^\d+$/);
export default {
  name: 'Signup',
  beforeCreate() {
    Vue.use(VueMask);
    Vue.use(VCalendar);
  },
  components: {
    CodeInput,
    Datepicker
  },
  data() {
    return {
      en: en,
      ru: ru,
      kk: kk,
      //   date: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      emailConfirm: false,
      buttonBlock: false,
      acceptData: false,
      imagesName: [],
      CurentStep: 1,
      stepOne: false,
      stepTwo: false,
      stepThree: false,
      isHiddenSignup: false,
      isHiddenSignin: true,
      isResident: true,
      iinCorrect: true,
      errorMessage: '',
      education: {},
      localities: [],
      regions: [],
      cities: [],
      schools: [],
      phone: '',
      emailValid: false,
      stringurl: '',
      regionsLoading: false,
      localitiesLoading: false,
      citiesLoading: false,
      schoolsLoading: false,
      invalidCode: false,
      step2: false,
      filevalid: false,
      supportFiles: ['pdf', 'jpeg', 'png', 'gif', 'docx', 'pptx'],
      submitted: false,
      passwordValid: false,
      password: '',
      confirm_password: '',
      subjects: '',
      hours: {
        hour0900: true,
        hour0930: true,
        hour1000: true,
        hour1030: true,
        hour1100: true,
        hour1130: true,
        hour1200: true,
        hour1230: true,
        hour1300: true,
        hour1330: true,
        hour1400: true,
        hour1430: true,
        hour1500: true,
        hour1530: true,
        hour1600: true,
        hour1630: true,
        hour1700: true,
        hour1730: true,
        hour1800: true,
      },
      busyhour: [],
      account: {
        role: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        birth_date: '',
        locality_id: '',
        region_id: '',
        city_id: '',
        language_id: '',
        subject: '',
        change_of_training_id: '',
        phone: '',
        email: '',
        interview_date: '',
        interview_hour: '',
        images: [],
      },
    }
  },


  mounted() {


    this.$http.get(window.API_ROOT + '/api/reference/subjects')
        .then((res) => {
          if (res.body.data) {
            this.subjects = res.body.data;
          }
        }).catch((e) => {
    })
    this.regionsLoading = true;
    this.$http.get(window.API_ROOT + '/api/reference/regions')
        .then((res) => {
          if (res.body.data) {
            this.regionsLoading = false;
            this.regions = res.body.data;
          }
        }).catch((e) => {
      this.regionsLoading = false
    })

  },
  methods: {
    onComplete(v) {
      this.$http.post(window.API_ROOT + '/api/register-form/email/verify', {
        'email': this.account.email,
        'code': v
      }).then(function (res) {
        console.log(res)
        if (Object.values(res.body)[0] == true) {
          this.invalidCode = false
          this.$bvModal.hide('sms-modal');
          this.emailConfirm = true
          this.CurentStep = 3

        } else {
          this.invalidCode = true
        }

      })
    },
    onChange(v) {
      // console.log('on change');
    },

    emailValidate() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(this.account.email).toLowerCase()) == false) {
        Vue.toastr({
          message: this.$t('signup.error'),
          description: this.$t('signup.email-error'),
          type: 'error'
        })
        this.emailValid = false
      } else {
        this.emailValid = true
      }

    },
    emailConfirmModal(step) {
      if (this.emailConfirm == true) {
        this.CurentStep = step
      } else {
        this.step2 = true;
        this.$http.post(window.API_ROOT + '/api/register-form/email/verify/send', {
          'email': this.account.email,
        }).then(function (res) {
          if (Object.values(res.body)[0] == true) {
            this.$bvModal.show('sms-modal');
            this.step2 = false;
          } else {
            this.step2 = false;
            Vue.toastr({
              message: this.$t('signup.error'),
              description: '',
              type: 'error'
            });
          }

        })


      }
    },
    changeStep(step) {
      if (this.CurentStep == 1) {
        if (this.account.patronymic == false || this.account.first_name == false || this.account.last_name == false || this.account.birth_date == false || this.account.role == false || this.account.locality_id == false) {
          Vue.toastr({
            message: this.$t('signup.error'),
            description: this.$t('signup.all-field-error'),
            type: 'error'
          });
          this.stepOne = false
        } else {
          this.stepOne = true
          if (step == 2) {
            this.CurentStep = step
          } else if (step == 3) {
            if (this.stepTree == true) {
              this.CurentStep = step
            }
          }
        }
      }
      if (this.CurentStep == 2) {
        if (this.account.role == 2 && step != 2) {
          if (this.emailValid == false || this.account.email == false || this.phone == false || this.account.change_of_training_id == false || this.account.language_id == false || this.account.images == false) {
            Vue.toastr({
              message: this.$t('signup.error'),
              description: this.$t('signup.all-field-error'),
              type: 'error'
            });
            this.stepTwo = false
          } else {
            this.emailConfirmModal(step)
          }
        }
        if (this.account.role == 3 && step != 2) {
          if (this.emailValid == false || this.account.email == false || this.phone == false || this.account.change_of_training_id == false || this.account.images == false) {
            Vue.toastr({
              message: this.$t('signup.error'),
              description: this.$t('signup.all-field-error'),
              type: 'error'
            });
            this.stepTwo = false
          } else {
            this.emailConfirmModal(step)
          }
        }
        if (this.account.role == 4 && step != 2) {
          if (this.emailValid == false || this.account.email == false || this.phone == false || this.account.change_of_training_id == false || this.account.images == false) {
            Vue.toastr({
              message: this.$t('signup.error'),
              description: this.$t('signup.all-field-error'),
              type: 'error'
            });
            this.stepTwo = false
          } else {
            this.emailConfirmModal(step)
          }
        }


      }
      if (this.CurentStep == 3) {
        this.CurentStep = step
      }

    },
    getBusyTime() {
      this.$http.get(window.API_ROOT + '/api/time?date=' + this.account.interview_date)
          .then((res) => {
            console.log(res.body.data)
            this.busyhour = res.body.data

            this.hours.hour0900 = true
            this.hours.hour0930 = true
            this.hours.hour1000 = true
            this.hours.hour1030 = true
            this.hours.hour1100 = true
            this.hours.hour1130 = true
            this.hours.hour1200 = true
            this.hours.hour1230 = true
            this.hours.hour1300 = true
            this.hours.hour1330 = true
            this.hours.hour1400 = true
            this.hours.hour1430 = true
            this.hours.hour1500 = true
            this.hours.hour1530 = true
            this.hours.hour1600 = true
            this.hours.hour1630 = true
            this.hours.hour1700 = true
            this.hours.hour1730 = true
            this.hours.hour1800 = true

            for (var i = 0; i < this.busyhour.length; i++) {
              if (this.busyhour[i] == '09:00:00') {
                this.hours.hour0900 = false
              }
              if (this.busyhour[i] == '09:30:00') {
                this.hours.hour0930 = false
              }
              if (this.busyhour[i] == '10:00:00') {
                this.hours.hour1000 = false
              }
              if (this.busyhour[i] == '10:30:00') {
                this.hours.hour1030 = false
              }
              if (this.busyhour[i] == '11:00:00') {
                this.hours.hour1100 = false
              }
              if (this.busyhour[i] == '11:30:00') {
                this.hours.hour1130 = false
              }
              if (this.busyhour[i] == '12:00:00') {
                this.hours.hour1200 = false
              }
              if (this.busyhour[i] == '12:30:00') {
                this.hours.hour1230 = false
              }
              if (this.busyhour[i] == '13:00:00') {
                this.hours.hour1300 = false
              }
              if (this.busyhour[i] == '13:30:00') {
                this.hours.hour1330 = false
              }
              if (this.busyhour[i] == '14:00:00') {
                this.hours.hour1400 = false
              }
              if (this.busyhour[i] == '14:30:00') {
                this.hours.hour1430 = false
              }
              if (this.busyhour[i] == '15:00:00') {
                this.hours.hour1500 = false
              }
              if (this.busyhour[i] == '15:30:00') {
                this.hours.hour1530 = false
              }
              if (this.busyhour[i] == '16:00:00') {
                this.hours.hour1600 = false
              }
              if (this.busyhour[i] == '16:30:00') {
                this.hours.hour1630 = false
              }
              if (this.busyhour[i] == '17:00:00') {
                this.hours.hour1700 = false
              }
              if (this.busyhour[i] == '17:30:00') {
                this.hours.hour1730 = false
              }
              if (this.busyhour[i] == '18:00:00') {
                this.hours.hour1800 = false
              }
            }

          }).catch((e) => {
      })

    },
    sendform() {
      if (this.account.language_id == 1) {
        this.account.language_id = 'kz'
      } else if (this.account.language_id == 2) {
        this.account.language_id = 'ru'
      }
      let request;
      if (this.account.role == 2) {
        request = '/api/register-form/create?first_name=' + this.account.first_name + '&patronymic=' + this.account.patronymic + '&last_name=' + this.account.last_name + '&email=' + this.account.email + '&phone=' + this.phone + '&birthdate=' + this.account.birth_date + '&role_id=' + this.account.role + '&locality_id=' + this.account.locality_id + '&subject_id=' + this.account.subject + '&shift=' + this.account.change_of_training_id + '&lang=' + this.account.language_id + '&interview_date=' + this.account.interview_date + '&interview_time=' + this.account.interview_hour;
      } else if (this.account.role == 3) {
        request = '/api/register-form/create?first_name=' + this.account.first_name + '&patronymic=' + this.account.patronymic + '&last_name=' + this.account.last_name + '&email=' + this.account.email + '&phone=' + this.phone + '&birthdate=' + this.account.birth_date + '&role_id=' + this.account.role + '&locality_id=' + this.account.locality_id + '&subject_id=' + this.account.subject + '&shift=' + this.account.change_of_training_id + '&interview_date=' + this.account.interview_date + '&interview_time=' + this.account.interview_hour;
      } else if (this.account.role == 4) {
        request = '/api/register-form/create?first_name=' + this.account.first_name + '&patronymic=' + this.account.patronymic + '&last_name=' + this.account.last_name + '&email=' + this.account.email + '&phone=' + this.phone + '&birthdate=' + this.account.birth_date + '&role_id=' + this.account.role + '&locality_id=' + this.account.locality_id + '&shift=' + this.account.change_of_training_id + '&interview_date=' + this.account.interview_date + '&interview_time=' + this.account.interview_hour;
      }

      console.log(request);
      console.log(this.stringurl)
      this.stringurl = this.stringurl.substring(0, this.stringurl.length - 1).split(' ');
      console.log(this.stringurl)
      this.buttonBlock = false
      this.$http.post(window.API_ROOT + request, {"cv_file": this.stringurl})
          .then((res) => {
            Vue.toastr({
              message: this.$t('signup.succes'),
              description: this.$t('signup.succes-descript'),
              type: 'success'
            });
            this.$router.push({path: '/'})
          }).catch((e) => {
      });


    },
    sendRequest() {
      if (this.account.role == false) {
        Vue.toastr({
          message: this.$t('signup.error'),
          description: this.$t('signup.select-role'),
          type: 'error'
        });
      } else if (this.account.role == 2) {
        if (this.account.first_name == false || this.account.last_name == false || this.account.email == false || this.phone == false || this.account.birth_date == false || this.account.role == false || this.account.locality_id == false || this.account.change_of_training_id == false || this.account.language_id == false || this.account.images == false || this.account.interview_date == false || this.account.interview_hour == false) {
          Vue.toastr({
            message: this.$t('signup.error'),
            description: this.$t('signup.all-field-error'),
            type: 'error'
          });
        } else {
          this.upploadFile(this.sendform)
        }
      } else if (this.account.role == 3) {
        if (this.account.first_name == false || this.account.last_name == false || this.account.email == false || this.phone == false || this.account.birth_date == false || this.account.role == false || this.account.locality_id == false || this.account.change_of_training_id == false || this.account.images == false || this.account.interview_date == false || this.account.interview_hour == false) {
          Vue.toastr({
            message: this.$t('signup.error'),
            description: this.$t('signup.all-field-error'),
            type: 'error'
          });
        } else {
          this.upploadFile(this.sendform)
        }
      } else if (this.account.role == 4) {
        if (this.account.first_name == false || this.account.last_name == false || this.account.email == false || this.phone == false || this.account.birth_date == false || this.account.role == false || this.account.locality_id == false || this.account.change_of_training_id == false || this.account.images == false || this.account.interview_date == false || this.account.interview_hour == false) {
          Vue.toastr({
            message: this.$t('signup.error'),
            description: this.$t('signup.all-field-error'),
            type: 'error'
          });
        } else {

          this.upploadFile(this.sendform)
        }
      }


    },
    onFileChange(e) {
      if (this.account.images.length < 5) {
        console.log(this.imagesName)
        var fileData = event.target.files[0];
        this.createImages(e.target.files || e.dataTransfer.files, fileData.name ? fileData.name : "");
      } else {
        return
      }
    },
    createImages(files, filenames) {
      [...files].forEach(file => {
        var extArray = filenames.split(".");
        var ext = extArray[extArray.length - 1];
        if (ext != 'pdf' & ext != 'jpeg' & ext != 'png' & ext != 'gif' & ext != 'pptx' & ext != 'docx'& ext != 'doc') {
          Vue.toastr({
            message: this.$t('signup.error'),
            description: this.$t('signup.file-error'),
            type: 'error'
          });
          return
        }
        this.imagesName.push(filenames);
        const reader = new FileReader();
        reader.onload = e => this.account.images.push(e.target.result);
        reader.readAsDataURL(file);
      });
    },
    removeImage(index) {
      this.account.images.splice(index, 1);
      this.imagesName.splice(index, 1);
    },
    filesUrlString(string) {

    },
    upploadFile(callback) {
      this.buttonBlock = true
      this.stringurl = ''
      for (let x = 0; x < this.account.images.length; x++) {
        let self = this
        self.$http.post(window.API_ROOT + '/api/file', {
          'file': this.account.images[x],
          'type': 'avatar'
        }).then(function (res) {
          self.stringurl = Object.values(res.body.data).join() + ' ' + self.stringurl
          console.log(self.stringurl + '-------')
          if (this.account.images.length == x + 1) {
            setTimeout(callback, this.account.images.length * 1000);
          }
        })

      }


    },


    getCities(id) {
      this.citiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/reference/districts/' + id)
          .then((res) => {
            this.citiesLoading = false;
            if (res.body.data) {
              this.cities = res.body.data;
              this.account.city_id = '';
            }
          }).catch((e) => {
        this.citiesLoading = false;
      })
    }
    ,
    getLocalities(id) {
      this.localitiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/reference/localities/' + id)
          .then((res) => {
            this.localitiesLoading = false;
            if (res.body.data) {
              this.localities = res.body.data;
              this.account.locality_id = '';
            }
          }).catch((e) => {
        this.localitiesLoading = false;
      })
    }
    ,


  }
}
</script>


<style lang="less" scoped>
.uppload-file-header-text {
  cursor: pointer;
  position: absolute;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(17, 17, 17, 0.48);
  left: 30px;
}

.uppload-file-wrapper {
  //   margin-bottom: 6rem;
  padding: 15px;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
}

.uppload-file-wrapper .uppload-file-header span {
  display: flex;
  align-items: center;
}

.uppload-file-header {
  display: flex;
  cursor: pointer;
  position: relative;
}

.uppload-file-element {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.uppload-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 14rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(17, 17, 17, 0.48);
}

.uppload-delete {
  background-repeat: no-repeat;
  border: none;
  width: 20px;
  height: 20px;
  background-image: url("/images/Button-close.svg");
}

.uppload-icon {
  cursor: pointer;
  display: inline-flex;
  margin: 3px 0;
  background-repeat: no-repeat;
  border: none;
  width: 17px;
  height: 17px;
  background-image: url("/images/link_02.svg");
  background-size: contain;
}

.custom-file-upload {
  cursor: pointer;
}

input[type='file'] {
  font-size: 0;
  position: absolute;
  z-index: 100;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  height: 30px;

}

.teacher-hour-wrapper {
  padding: 4rem 2rem;
  display: flex;
  margin-top: 2rem;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 4px;
}

.teacher-hour-column {
  width: 50%;
}

.teacher-text-purple {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #473F95;
}

.form-subtitle {
  color: #303030;
  font-size: 13px;
  font-weight: normal;
  line-height: 13px;
}

.auth-form__close {
  border: none;
  position: absolute;
  background-color: transparent;
  right: 16px;
  top: 16px;
}

/deep/ .auth-form .nav-tabs {
  border-bottom: 1px solid transparent;
}

.step-1, .step-2, .step-3, .step-4 {
  cursor: pointer;
  line-height: 15px;
  white-space: nowrap;
  font-size: 13px;
  color: #473F95;
  background-color: #edebf4;
  padding: 6px 26px 7px 28px;
  position: relative;
  margin-right: 13px;
  font-weight: 500;
}

.step-1 {
  border-radius: 22px 0 0 22px;
}

.step-4 {
  border-radius: 0 22px 22px 0;
}

.step-2:before,
.step-3:before,
.step-4:before {
  content: "";
  width: 0;
  height: 28px;
  left: 0;
  top: 0;
  position: absolute;
  border-top: 13px solid transparent;
  border-left: 10px solid #fff;
  border-bottom: 13px solid transparent;
}

.step-1:after,
.step-2:after,
.step-3:after {
  content: "";
  width: 0;
  height: 28px;
  right: -10px;
  top: 0;
  position: absolute;
  border-top: 13px solid transparent;
  border-left: 10px solid #edebf4;
  border-bottom: 13px solid transparent;
}

.stepActive {
  background-color: #473F95;
  color: #fff;
}

.stepActive:after {
  border-left: 10px solid #473F95;
}

.auth-form__application-steps {
  margin-top: 20px;
  padding: 0px 35px;
  border-top: 1px solid #F1F1F1;
}

.el-select {
  display: inline-block;
  position: relative;
  width: 100%;
}

.form-control input {
  border: none;
  line-height: 1.5;
  color: #495057;
  /* #c0c4cc */
  font-size: 16px;
}

.teacher-page .form-group {
  margin-bottom: 8px;
}

.teacher-page .form-body .btn {
  border-radius: 4px;
  margin-top: 54px;
  margin-bottom: 30px;
}

/deep/ .signin-form__button .btn {
  border-radius: 4px !important;
}

/deep/ .teacher-page .el-input__inner {
  height: 46px;
  font-size: 16px;
}

.form-check-label {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 14px;
  color: #303030;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-top: 3px;
}

.form-check-label:hover input ~ .checkmark {
  background-color: #eee;
}

.form-check-label input:checked ~ .checkmark {
  background-color: #473F95;
}

.form-check-label input:checked .form-check-label {
  background-color: #473F95;
}

.checkmark:after {
  content: "✓";
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .checkmark:after {
  display: block;
}

.form-check-label .checkmark:after {
  top: 2px;
  left: 4px;
  color: #fff;
  font-size: 11px;
  color: #fff;
}


/deep/ .teacher-date-picker .teacher-date-picker__input {
  width: 100%;
  background-color: #fff;
  height: 46px;
  font-size: 16px;
  box-shadow: 0px 4px 4px rgb(51 51 51 / 4%), 0px 4px 16px rgb(51 51 51 / 8%);
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 0 30px 0 15px;
  color: #c4c7ce;
  font-size: 16px;
  line-height: 24px;
}

/deep/ .teacher-date-picker .teacher-date-picker__input::placeholder {
  color: #c4c7ce;
  opacity: 1;
}

/deep/ .teacher-date-picker .teacher-date-picker__input:-ms-input-placeholder {
  color: #c4c7ce;
}

/deep/ .teacher-date-picker .teacher-date-picker__input::-ms-input-placeholder {
  color: #c4c7ce;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar {
  border: none;
  width: 100%;
  box-shadow: 0px 4px 4px rgb(51 51 51 / 4%), 0px 4px 16px rgb(51 51 51 / 8%);
  border-radius: 4px;
  padding: 12px 14px;
}

/deep/ .teacher-date-picker .day__month_btn {
  color: #473F95;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar header .prev:not(.disabled):hover,
/deep/ .teacher-date-picker .vdp-datepicker__calendar header .next:not(.disabled):hover,
/deep/ .teacher-date-picker .vdp-datepicker__calendar header .up:not(.disabled):hover {
  background-color: #fff;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar header .prev:after {
  border-right: 10px solid #473F95;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar header .next:after {
  border-left: 10px solid #473F95;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar .cell.day-header {
  color: #232A3E;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar .cell {
  border: 1px solid #EDF2F7;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 2px;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar .cell.day.blank {
  visibility: hidden;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar .cell.day-header {
  border: none;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar .cell.day.today {
  color: #d23168;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
/deep/ .teacher-date-picker .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
/deep/ .teacher-date-picker .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid #473F95;
}

/deep/ .teacher-date-picker .vdp-datepicker__calendar .cell.day.selected {
  background-color: #473F95;
  color: #fff;

}

.auth-form.card {
  max-width: 406px;
  margin-left: auto;
  margin-right: 0;
}

.btn-close-top {
  background: #473F95;
  border-radius: 100px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: absolute;
  top: 6px;
  right: 6px;
}

.sms-modal-title {
  color: #473F95;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 8px;
  font-family: Roboto;
}

.sms-modal-text {
  color: #303030;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 22px;
  text-align: center;
  font-family: Roboto;
}

.sms-modal-send-again {
  color: #D23168;
  letter-spacing: 0.1px;
  font-size: 12px;
  line-height: 16px;
  padding-top: 16px;
  text-align: center;
  padding-bottom: 50px;
}

/deep/ #sms-modal .modal-body {
  background-image: url("/images/modal-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

/deep/ .react-code-input > input {
  box-shadow: 0px 4px 4px rgb(51 51 51 / 4%), 0px 4px 16px rgb(51 51 51 / 8%);
  border-radius: 4px;
  border: none;
  margin-right: 20px;
  font-size: 40px;
  line-height: 24px;
  color: #473F95;
}

/deep/ .react-code-input > input:last-child {
  margin-right: 0;
  border-right: none;
}

/deep/ .react-code-input > input:focus {
  border-color: transparent;
}

/deep/ .react-code-input-container {
  margin: 0 auto;
  width: fit-content !important;
}

@media screen and (max-width: 1200px) {
  .step-1, .step-2, .step-3, .step-4 {
    padding: 6px 29px 7px 29px;
    margin-right: 10px;
  }

  /deep/ .teacher-date-picker .vdp-datepicker__calendar {
    padding: 12px 17px;
  }

  /deep/ .teacher-date-picker .vdp-datepicker__calendar .cell {
    width: 34px;
    height: 34px;
    line-height: 34px;
    margin: 2px;
  }
}

@media screen and (max-width: 991px) {
  /deep/ .teacher-date-picker .vdp-datepicker__calendar .cell {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 2px 4px;
  }

  /deep/ .teacher-date-picker .vdp-datepicker__calendar {
    padding: 12px 20px;
  }
}

@media screen and (max-width: 436px) {
  /deep/ .teacher-date-picker .vdp-datepicker__calendar .cell {
    width: 34px;
    height: 34px;
    line-height: 34px;
    margin: 2px;
  }

  /deep/ .teacher-date-picker .vdp-datepicker__calendar {
    padding: 12px 24px;
  }
}
</style>
